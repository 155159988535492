.element {
    display: flex;
    flex-direction: row;
    color: #B4B4B4;
    padding: 0;
    align-items: center;
    margin: 12px 0
}

.element p.info {
    font-weight: 500;
    margin-bottom: 0;
}

@media only screen and (max-width: 768px) {
    .element {
        align-items: baseline;
    }
}