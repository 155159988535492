.cardContent {
    width: 100%;
    padding: 0!important
}

.iconRow {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 12px 0
}

.iconRow p {
    font-weight: 600;
}

.headerIcon {
    font-size: 18px;
    margin-right: 8px;
}


.diplomNotSet {
    color: #B4B4B4;
    font-weight: 600;
    font-size: 14px;
}

.diplomNotSet p {
    font-size: 16px;
    color: #B4B4B4;
}

.gotIn {
    color: #639C26;
    font-weight: 600;
}

.gotIn p {
    color: #639C26
}

.notIn {
    color: #D02B2B;
    font-weight: 600;
}

.notIn p {
    color: #D02B2B
}

.previousYearsInfo h3 {
    font-size: 16px;
    color: #3B5222;
    font-weight: 600;
    padding: 0;
    margin: 8px 0 16px 0
}


.programName h2 {
    font-size: 18px;
    line-height: 26px;
    color: #3B5222;
    font-weight: 600;
    padding: 0;
    margin: 16px 0
}

.year {
    background-color: #F6F6F6;
    width: 100%;
    display: flex;
    flex-direction: column;
    border-radius: 5px;
    padding: 16px 16px 20px 16px;
    margin-bottom: 8px;
}

.year h5 {
    font-size: 16px;
    color: #3B5222;
    font-weight: 600;
    margin: 16px 0 8px 0
}

.year p {
    font-size: 12px;
    color: #949494;
    margin-bottom: 4px;
}

.yearRow h4 {
    font-size: 21px;
    color:#3B5222;
    font-weight: 600;
    margin-bottom: 16px
}

.white {
    display: flex;
    flex-direction: row;
    width: 100%;
    background-color: #fff;
    border-radius: 5px; 
    align-items: center;
    padding: 6px 12px
}

.gray {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items: center;
    padding: 6px 12px
}

.contentCol {
    display: flex;
    flex-grow: 1;
    font-size: 14px;
}

.iconCol {
    display: flex;
    flex-grow: 0;
}

.icon {
    font-size: 18px;
}

.textCol {
    display: flex;
    flex-grow: 0;
    font-size: 14px;
    font-weight: 600;
    padding: 4px 0
}
.pointCol {
    display: flex;
    flex-grow: 0;
    font-size: 14px;
    font-weight: 600;
    color:#3B5222;
    padding: 4px 0
}

.pointCol p {
    display: flex;
    flex-grow: 0;
    font-size: 14px;
    font-weight: 600;
    color:#3B5222;
    padding: 4px 0
}
.noRequirementsRow {
    display: flex;
    flex-direction: row;
    padding: 0;
    align-items: center;
}
.noRequirementsRow p {
    font-weight: 600;
    font-size: 14px;
}

.unknown {
    color:#949494;
    font-weight: 600;
}

.unknown p {
    font-size: 14px;
    margin-top: 2px;
}

.textNormal {
    font-size: 14px;
    color:#3B5222;
}

.infoRow {
    width: 100%;
    display: flex;
    flex-direction: row;
    padding: 0 0 8px 0
}

.subheader {
    display: flex;
    flex-direction: row;
    color: #949494;
    align-items: center;
    margin-right: 30px;
    width: max-content
}

.subheader p {
    font-size: 14px
}