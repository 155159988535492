.cardStyle {
  margin-right: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  text-align: center;
}

.cardStyle h3 {
  font-weight: 600;
  color:#3B5222;
  font-size: 18px;
}

.cardStyle:last-child {
  margin-right: 0;
}

.imgStyle {
  width: 200px; 
  height: 200px; 
  padding: 0;
  border-radius: 10px;
  display: flex;
  margin-bottom: 16px;
}

.buttonRow {
  margin-top: 16px;
}

@media only screen and (max-width: 768px) {

  .cardStyle {
      margin-right: 0;
      margin-bottom: 32px
  }

  .imgStyle {
      width: 100%;
      height: auto;
  }
}