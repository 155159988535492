.filterWrapper {
    background-color: #FCFBFB;
    padding-top: 50px;
}

.element {
    background-color: #fff;
    border-radius: 10px;
    padding:40px 60px;
    box-shadow: 0 4px 40px 10px rgb(9, 12, 6, 0.03);
    position: relative;
    z-index:0
}

.element h3 {
    font-size: 24px;
    color: #3B5222;
    font-weight: 600;
}

.element span {
    color: #3B5222;
    font-size: 16px;
}

.element h4 {
    color: #3B5222;
    font-size: 18px;
    font-weight: 600;
}

.search {
    background-color: #F6F6F6;
    border-style: hidden;
    border-radius: 5px;
    padding: 16px 16px 16px 62px;
    margin-right: 8px;
    margin-left: 12px;
    display: flex;
    width:max-content;
    flex-grow: 1;
    max-width: initial;
}

.buttonContainer {
    display: flex;
    flex-grow: 0;
    padding: 0;
    width: fit-content;
}

.searchButton {
    height:fit-content;
    padding: 10px 15px!important
}

.alignCenter {
    align-items: center;
}

.search:focus-visible {
    outline-color: #EAEAEA!important;
}

.search::placeholder {
    color: #949494
}

.searchColor {
    color:#949494;
    position: absolute;
    margin-left: 25px;
    font-size: 20px;
    width:fit-content
}

.closeButton {
    color:#949494;
    position: absolute;
    font-size: 20px;
    right: 204px;
    width:fit-content;
    transition: all ease-in 0.1s;
}

.closeButton:hover {
    color:#757575;
    cursor: pointer
}

@media only screen and (max-width: 1200px) {
    .search {
        width: 80%;
    }
}

@media only screen and (max-width: 1000px) {
    .search {
        width: 70%;
    }
}

@media only screen and (max-width: 768px) {
    .filterWrapper {
        padding: 32px 0 0 0 
    }
    .element {
        background-color: transparent;
        box-shadow: none;
        padding: 0;
        position: relative;
        z-index:0
    }

    .search {
        background-color: #fff;
        width: 100%;
        border: solid 1px #D6D6D6;
        border-radius: 5px;
        padding: 16px 16px 16px 50px;
        color:#565656;
        margin: 0;
    }
    .alignCenter {
        margin: 0!important;
        padding: 0
    }
    .searchColor {
        margin-left: 8px;
        font-size: 20px;
        width:fit-content;
    }
    .closeButton {
        right: 8px
    }

    .searchButton {
        margin: 24px 0 12px 0
    }
}