.landingArea {
    margin-top: 170px;
    margin-bottom: 70px
}

.header h1 {
    color: #3B5222;
    font-size: 48px;
    margin-bottom: 16px;
    font-weight:700;
}

.text p {
    color: #3B5222;
    font-size: 16px;
    margin-bottom: 28px
}

.homeImage {
    max-width: 600px;
    position: relative;
    z-index: -1;
}

.preHeader p {
    font-size: 14px
}

.navigate {
    background-color: #fff;
    width: 100%;
    border-radius: 5px;
    box-shadow: 0 4px 40px 10px rgb(9, 12, 6, 0.03);
}

.navigateRow {
    width: 100%;
    padding: 12px 20px;
    display: flex;
    text-decoration: none;
    color:#639C26; 
    font-weight: 500;
}

.navigateRow:last-child {
    border-radius: 0 0 5px 5px;
}

.navigateRow:first-child {
    border-radius: 5px 5px 0 0;
}

.navigateRow:active, .navigateRow:hover {
    background-color: #F6F6F6;
    color: #639C26;
}

.edgeColumn {
    display: flex;
    max-width: 15%;
    width: 15%;
    justify-content: end;
}

.linkColumn {
    display: flex;
    max-width: 70%;
    width: 70%;
    justify-content: center;
}

.testBorder {
    width: 100%;
    height: 1px;
    background: rgb(234,234,234);
    background: linear-gradient(90deg, rgba(234,234,234,0) 2%, rgba(234,234,234,1) 15%, rgba(234,234,224,1) 85%, rgba(234,234,234,0) 98%);
}

.element h2 {
    font-size: 18px;
    color:#3B5222;
    font-weight: 600;
}

.element {
    position: absolute;
    bottom: 24px;
    width: calc(100% - 48px);
    margin: 0 24px
}

@media only screen and (max-width: 1100px) {
    .homeImage {
        max-width: 360px;
        position: relative;
        z-index: -1;
    }
}

@media only screen and (max-width: 768px) {
    .landingArea {
        margin: 130px 0 0 -24px!important;
        min-height: calc(100vh - 130px);
        background-image: url(../static/images/illustration_mobile.png);
        background-repeat: no-repeat;
        background-size: cover;
        width: 100vw;
        text-align: center;
    }

    .header h1 {
        font-size: 32px;
    }

    .header {
        margin: 0!important
    }

    .text {
        margin: 0!important
    }

    .preHeader {
        margin: 0!important;
    }

    .preHeader p {
        font-size: 12px;
        margin-bottom: 4px!important
    }

    .text p {
        line-height: 24px;
    }
    @media only screen and (max-height: 740px) {
        .landingArea {
            margin-top: 100px!important;   
            min-height: calc(100vh - 100px); 
        }
    }

    @media only screen and (orientation: landscape) {
        .element {
            position: relative;
            margin-top: 60px;
        }
    }
    
}