.hoverElement {
    position: fixed;
    width: 60px;
    bottom: 25px;
    right: 25px;
    border-radius: 40px;
    border-style: solid;
    border-width: 0;
    border-color: #fff;
    box-shadow: 0 4px 40px 10px rgb(9, 12, 6, 0.03);
    transition: 0.5s all;
    background-color: #639C26;
    display: flex;
    flex-direction: row;
    align-items: center;
    z-index: 1
}

.hoverElementButton {
    width: 60px;
    min-width: 60px;
    height: 60px;
    border-radius: 30px;
    display: flex;
    align-items: center;
    margin-left: 20px;
}

.hoverIcon {
    color: #fff;
    font-size: 21px;
}

.text {
    display: flex;
}

.text p {
    color: #fff;
    font-weight: 500;
    margin-left: -8px;
}

.alignCenter {
    display: flex;
    align-items: center;
}

@media only screen and (min-width: 769px) {
    .hoverElement:hover {
        width: 260px;
        border-color: #92d050;
        cursor: pointer;
    }
}

@media only screen and (max-width: 768px) {
    .hoverElement {
        bottom: 16px;
        right: 16px;
    }
}