.title {
    font-size: 22px;
    line-height: 30px;
    font-weight: 600;
    color: #3B5222;
}

.customHeader {
    padding:20px 20px 12px 20px!important;
    justify-content: center!important
}

.customBody {
    border-bottom-style: hidden!important;
    padding: 20px!important;
    text-align: center
}

.customFooter {
    border-top-style: hidden!important;
    justify-content: space-between;
    padding: 0 20px 20px 32px!important
}

@media only screen and (max-width: 768px) {
    .customFooter {
        padding: 0 20px 20px 20px!important
    }
}