.notificationWrapper {
    position: fixed;
    z-index: 99999;
    top: 95px;
    right: 42px;
}

.notification {
    padding: 16px;
    margin-bottom: 12px;
    border-radius: 5px;
    border: solid 1px #EAEAEA;
    border-left: solid 6px #D02B2B;
    background-color: #fff;    
    box-shadow: 0 4px 40px 10px rgb(9, 12, 6, 0.03);
    display: flex;
    flex-direction: row;
    width: 380px;
    animation: fadeInLeft 0.5s;
}

.icon {
    font-size: 21px;
    color: #D02B2B;
    display: flex;
    width: 40px
}

.header h1 {
    font-size: 18px;
    color: #D02B2B;
    font-weight: 600;
}

.text p {
    font-size: 16px;
    color: #949494;
}

.textColumn {
    width: 90%;
    display: flex;
    flex-direction: column;
    padding-top: 6px;
}

.closeIcon {
    width: 10%;
    justify-content: end;
    color: #949494;
    display: flex;
}

.closeIcon:hover {
    cursor: pointer;
    color: #3B5222;;
}

@media only screen and (max-width: 768px) {
    .notificationWrapper {
        top: 15px;
        right: auto;
        width: calc(100% - 40px);
    }
    .notification {
        width: 100%;
    }
}

@keyframes fadeInLeft {
   0% {
      opacity: 0;
      transform: translateX(50px);
   }
   50% {
      opacity: 1;
      transform: translateX(0);
   }
   55% { transform: translate(1px, 1px) rotate(0deg); }
   60% { transform: translate(-1px, -2px) rotate(-1deg); }
   65% { transform: translate(-3px, 0px) rotate(1deg); }
   70% { transform: translate(3px, 2px) rotate(0deg); }
   75% { transform: translate(1px, -1px) rotate(1deg); }
   80% { transform: translate(-1px, 2px) rotate(-1deg); }
   85% { transform: translate(-3px, 1px) rotate(0deg); }
   90% { transform: translate(3px, 1px) rotate(-1deg); }
   95% { transform: translate(-1px, -1px) rotate(1deg); }
   100% { transform: translate(1px, 2px) rotate(0deg); }
}
