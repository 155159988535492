.cookies {
    position: fixed;
    bottom: 0;
    background-color: #fff;
    width: 100%;
    color: #3B5222;
    padding: 16px 0 20px 0;
    box-shadow: 0 -4px 40px 10px rgb(9, 12, 6, 0.05);
    z-index: 9999;
}

.linkStyle {
    color: #639C26;
    font-weight: 500;
    text-decoration: none;
    margin-left: 5px;
}

.linkStyle:hover {
    text-decoration: underline;
    color: #639C26;
}

.wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.textColumn {
    display: flex;
    width: 80%
}

.buttonColumn {
    display: flex;
    width: 20%;
    justify-content: center;
}

@media only screen and (max-width: 768px) {
    .wrapper {
        flex-direction: column;
    }
    .textColumn {
        width: 100%;
        text-align: center;
    }
    
    .buttonColumn {
        width: 100%;
        justify-content: center;
        padding-top: 24px;
    }
}