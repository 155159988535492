.wrapper {
    width: 32px;
    height: 32px
}

.element {
    width: 100%;
    height: 3px;
    border-radius: 1.5px;
    background-color: #3B5222;
}

.element:nth-child(1) {
    position: relative;
    top: 12px;
}

.element:nth-child(2) {
    position: relative;
    top: 20px
}

.rotate {
    transition: all ease 0.2s;
}
.rotate:nth-child(1) {
    transform: rotate(45deg) translateY(7.8px);
}

.rotate:nth-child(2) {
    transform: rotate(-45deg) translateY(-7.8px);
}

.menu {
    position:absolute;
    display: flex;
    flex-direction: column;
    height: calc(100vh - 65px);
    width: 100%;
    background-color: #fff;
    top: 65px;
    left: 0;
    z-index: 99999;
    padding: 40px 20px 0 20px;
    text-align: center;
}

.linkContainer {
    display: flex; 
    flex-direction: column
}

.linkContainer h1 {
    font-size: 21px;
    font-weight: 600;
    color:#3B5222;
    margin-bottom: 16px;
}

.navLink {
    text-decoration: none;
    color: #3B5222;
    transition: all 0.2s;
    padding: 8px 16px;
    background-color: #fff;
    border-radius: 5px;
    margin-bottom: 8px;
}

.navLink:last-child {
    text-decoration: none;
    color: #3B5222;
}

.navLink:hover {
    color:#3B5222;
    background-color: #F6F6F6;
}

.active {
    color:#639C26;
    font-weight: 500;
    background-color:#F2FFE4;
}

.active:hover {
    color:#639C26;
    font-weight: 500;
    background-color:#e3f7ce;
}

.bottomContainer {
    position: fixed;
    display: flex;
    flex-direction: column;
    bottom: 0;
    width: 100%;
    margin-left: -20px;
    padding: 0 24px 32px 24px;
    background-color: #fff;

}

.trinode {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.trinode p {
    font-size: 16px;
    font-weight: 600;
    color: #B5BABE
}

.trinodeLogo {
    width: 30px;
    height: 30px;
    margin-right: 8px
}

@media only screen and (orientation: landscape) {
    .bottomContainer {
        position: relative;
        display: flex;
        flex-direction: column;
        bottom: 0;
        width: 100%;
        padding: 32px 0 32px 36px;
    }

    .menu {
        position: absolute;
        display: flex;
        flex-direction: column;
        height: auto;
        max-height: calc(100vh - 65px);
        min-height: calc(100vh - 65px);
        width: 100%;
        background-color: #fff;
        top: 65px;
        left: 0;
        z-index: 99999;
        padding: 40px 20px 0 20px;
        text-align: center;
        overflow-y: scroll
    }
}

