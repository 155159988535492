.wrapper {
    position: fixed;
    width: 100vw;
    height: 100vh;
    padding: 24px;
    text-align: center;
    background-color: #fff;
    z-index: 9999;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}

.wrapper h1 {
    font-size: 32px;
    line-height: 40px;
    color: #3B5222;
    font-weight: 600;
}

.wrapper p {
    color: #B4B4B4
}

.animationRow {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 16px;
    flex-direction: column;
}

.dualRing {
    display: inline-block;
    width: 80px;
    height: 80px;
  }
  .dualRing:after {
    content: " ";
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    border: 6px solid #fff;
    border-color: #639C26 transparent #639C26 transparent;
    animation: dualRing 1.2s linear infinite;
  }
  @keyframes dualRing {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }