body {
    margin: 0;
    overflow-x: hidden!important;
    font-family: 'Roboto', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }
  
  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
      monospace;
  }
  
  html {
    scroll-behavior: smooth;
  }

.container {
    max-width: 1200px
}

.text-primary {
    color: #639C26!important
}

.button-primary {
    background-color: #639C26!important;
    border-color: #639C26!important;
    border-radius: 8px!important;
    font-weight:500!important;
    width: max-content!important;
    height: max-content!important;
    font-size: 16px!important;
    padding: 8px 16px
}

.outline-primary {
    background-color: #fff!important;
    border-color: #639C26!important;
    color:#639C26!important;
    border-radius: 8px!important; 
    font-weight:500!important;
    width: auto!important;
    font-size: 16px!important;
    padding: 8px 16px;
    text-decoration: none!important;
}

.button-primary:hover {
    background-color: #6d993d!important;
    border-color: #6d993d!important;
}

.outline-primary:hover {
    background-color: #6d993d!important;
    border-color: #639C26!important;
    color:#fff!important;
    border-radius: 8px!important; 
    font-weight:500;
    width: auto!important;
    font-size: 16px!important;
    padding: 8px 16px
}

.button-primary:hover {
    background-color: #52732e!important;
    border-color: #52732e!important;
    color:#fff!important;
    border-radius: 8px!important; 
    font-weight:500;
    width: auto!important;
    font-size: 16px!important;
    padding: 8px 16px
}

.button-primary:focus {
    background-color: #52732e!important;
    border-color: #52732e!important;
    color:#fff!important;
    border-radius: 8px!important; 
    font-weight:500;
    width: auto!important;
    font-size: 16px!important;
    padding: 8px 16px;
    box-shadow: none!important;
}
.button-secondary {
    background-color: transparent!important;
    border: none!important;
    color:#639C26!important; 
    font-weight:500;
    width: auto!important;
    font-size: 16px!important;
    text-decoration: none!important;
    box-shadow: none!important;
    transition: all ease 0.2s;
    padding: 8px 0!important;
}

.outline-primary:focus {
    box-shadow: none!important;
}

.button-secondary:hover {
    text-decoration: underline!important;
}

.icon-style {
    font-size: 28px;
}

.button-secondary:focus {
    box-shadow: none;
}

.button-secondary:active {
    box-shadow: none;
}

.button-secondary-small {
    background-color: transparent!important;
    border: none!important;
    color:#639C26!important; 
    font-weight:500;
    width: auto!important;
    font-size: 16px!important;
    text-decoration: none!important;
    box-shadow: none!important;
    padding-left: 0!important;
}

.button-secondary-small:focus {
    box-shadow: none;
}

.button-secondary-small:active {
    box-shadow: none;
}

.button-disabled, .button-disabled:hover, .button-disabled:focus {
    background-color: #889b74!important;
    border-color: #889b74!important;
    border-radius: 8px!important;
    font-weight:500!important;
    width: max-content!important;
    font-size: 16px!important;
    padding: 8px 16px;
    cursor: not-allowed;
}

@media only screen and (max-width: 1200px) {
    .container {
        padding: 0px 48px
    }
}

@media only screen and (max-width: 768px) {
    .container {
        padding: 0px 24px
    }

    .button-secondary, .outline-primary, .button-primary, .button-disabled, .button-primary:focus, .button-primary:hover {
        width: 100%!important;
    }

    .button-secondary {
        transition: all ease-in 0.2s;
    }
    .button-secondary:hover {
        text-decoration: none!important;
    }

    .button-secondary:focus, .button-secondary:active{
        text-decoration: none!important;
        color: #52732e!important;
    }
}

@media (max-width: 769px) {
    .col-md-6 {
        flex: 0 0 auto!important;
        width: 100%!important;
    }
}
