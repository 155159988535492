.element {
    display: flex;
    flex-direction: row;
    width: 100%;
    align-items:flex-start;
}

.element:hover {
    cursor: pointer
}

.box {
    display: flex;
    border: solid 1px #EAEAEA;
    border-radius: 3px;
    width: 20px; 
    height: 20px;
    color: transparent;
    align-items: center;
    justify-content: center;
}

.active {
    display: flex;
    border: solid 1px #639C26;
    border-radius: 3px;
    width: 20px; 
    height: 20px;
    background-color: #639C26;
    align-items: center;
    color: #fff;
    justify-content: center;
}

.label {
    margin-left: 12px;
    margin-top: -2px
}

.element:hover .box {
    background-color: #F6F6F6;
    color: #cbcbcb
}

.disabled {
    color: #949494;
    display: flex;
}

.disabled:hover .box {
    color: transparent;
    background-color: transparent;
}

.disabled:hover {
    cursor: not-allowed
}