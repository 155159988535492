.tableTextNormal {
  font-size: 16px;
  font-weight: 600;
  color:#3B5222
}

.tableTextNormal p {
  margin: 0;
}

.diplomNotSet {
  color: #B4B4B4;
  font-weight: 600;
}

.diplomNotSet p {
  color: #B4B4B4
}

.noRequirementsRow {
  display: flex;
  flex-direction: row;
  padding: 0;
  margin-bottom: 24px;
}
.noRequirementsRow p {
  font-weight: 600;
}

.headerIcon {
  font-size: 18px;
  margin-right: 8px;
}

.unknown {
  color:#949494;
  font-weight: 600;
  display: flex;
}

.unknown p {
  font-size: 14px;
  margin-top: 2px;
}

@media only screen and (max-width: 768px) {
  .tableTextNormal {
    font-size: 14px;
  }

  .tableTextNormal p {
    margin: 0;
    font-weight: 600;
    color:#3B5222
  }
  .noRequirementsRow p {
    font-weight: 600;
    font-size: 14px;
  }
}