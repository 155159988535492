.element {
    width: auto!important;
    border-radius: 10px 10px 0 0;
    height: 240px!important;
    border-style: hidden!important;
    padding: 20px;
}

.header {
    justify-content: center;
    align-items: initial;
    padding: 0;
    padding-bottom: 16px;
    margin-right: -24px;
    border-bottom: solid 1px #EAEAEA;
    width: calc(100% + 48px);
    margin-left: -24px
}

.close {
    position: absolute;
    top: 13px;
    left: 24px;
}

.dropdownRow {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    padding: 16px;
    color: #3B5222;
    transition: all ease-in 0.2s
}
.dropdownRow:first-child {
    margin-bottom: 4px
}

.icon {
    margin-right: 8px
}

.chosen {
    background-color: #F2FFE4;
    color: #639C26;
    font-weight: 500;
}

.checkIcon {
    margin-left: 30px;
}

.flexFirst {
    display: flex;
    flex-grow: 1;
}

.flexSecond {
    display: flex;
    flex-grow: 0;
}

.disabled {
    color: #949494;
}

.disabled:hover {
    cursor:auto;
    background-color: #fff;
}

.body {
    padding: 16px 0!important
}

