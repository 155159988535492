.element {
    width: 300px;
    background-color: #fff;
    padding: 20px 20px 0 20px;
    border-radius: 10px;
    box-shadow: 0 4px 40px 10px rgb(9, 12, 6, 0.03);
    text-align: center;
    display: inline-block;
}

.element h2 {
    font-size: 16px;
    color: #3B5222;
    font-weight: 600;
}

.progressRowNoDiplom {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #CECECE;
}

.progressRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    color: #639C26;
}

.progressRow p {
    font-size: 32px!important;
}

.progressbar {
    width: 200px;
}

.resultRow {
    border-top: solid 1px #EAEAEA;
    width: calc(100% + 40px);
    margin-left: -20px;
    margin-top: 28px;
    display: flex;
}

.gotInColumn, .totalColumn {
    width: 50%;
    padding: 8px 0;
    display: flex;
    flex-direction: column;
}

.gotInColumn {
    border-right: solid 1px #EAEAEA;
}

.totalColumn h3 {
    font-size: 18px;
    font-weight: 600;
    color:#3B5222
}

.totalColumn p {
    font-size: 14px!important;
    color:#3B5222
}

.gotInColumn h3 {
    font-size: 18px;
    font-weight: 600;
    color:#639C26
}

.gotInColumn p {
    font-size: 14px!important;
    color:#639C26
}

@media only screen and (max-width: 768px) {
    .element {
        width: 100%;
        max-width: 400px;
        margin-top: 40px
    }
}