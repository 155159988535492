.wrapper {
    background-color: #F8F8EE;
    padding: 60px 0
}

.image {
    width: 100%;
}

.wrapper h1 {
    font-size: 36px;
    color: #3B5222;
    font-weight:600;
}

.alignCenter {
    align-items: center;
    display: flex;
}

.colAlign {
    display: flex;
    flex-direction: column;
}

@media only screen and (max-width: 768px) {
    .wrapper h1 {
        font-size: 28px;
    }
    
    .alignCenter {
        flex-direction: column-reverse;
    }

    .colAlign {
        text-align: center;
    }

    .image {
        margin-bottom: 36px
    }
}