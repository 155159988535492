.navbar {
    background-color: #fff;
    border-bottom: solid 1px #f5f5f5;
    box-shadow: 0 0 20px 16px rgba(0,0,0,0.02);
    padding: 12px 24px;
    display: flex!important;
    position: fixed;
    top: 0;
    z-index: 1042
}

@media only screen and (min-width: 1100px) {
    .navbar {
        display:none!important;
    }
}
