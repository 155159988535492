.card {
    background-color: #fff;
    padding: 24px 30px 20px 30px;
    width: 100%;
    border-radius: 10px;
    border: solid 1px #EAEAEA;
    margin-bottom: 8px
}

.cardRow {
    display: flex;
    flex-direction: row;
}

.contentCol {
    display: flex;
    flex-grow: 1;
    flex-direction: column;
    padding:0 12px;
}

.contentCol h2 {
    font-size: 18px;
    line-height: 24px;
    font-weight: 600;
    color: #3B5222;
}

.iconCol {
    display: flex;
    flex-grow: 0;
    padding:0 12px;
    flex-direction: column;
}

.iconRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 4px
}

.location {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 8px;
}

.location p {
    color: #949494;
    font-size: 14px;
    margin-bottom: 8px
}

.showMore {
    width: fit-content
}

.headerIcon {
    font-size: 18px;
    margin-right: 8px;
}

.iconRow p {
    font-weight: 600;
}

.noDiplom {
    color: #B4B4B4
}

.noDiplom p {
    color: #B4B4B4
}

.diplomNotSet {
    color: #B4B4B4
}

.diplomNotSet p {
    color: #B4B4B4
}

.gotIn {
    color: #639C26
}

.gotIn p {
    color: #639C26
}

.notIn {
    color: #D02B2B
}

.notIn p {
    color: #D02B2B
}

.toggleWrapper {
    width: fit-content
}

@media only screen and (max-width: 768px) {
    .card {
        padding: 16px 24px;
    }

    .cardRow {
        justify-content: space-between;
    }
    .headerIcon {
        font-size: 16px;
        margin-right: 8px;
    }
    .iconRow p {
        font-size: 14px;
    }
    .iconCol {
        padding: 0;
    }

    .contentCol {
        padding: 0 10px 0 0
    }

    .location {
        margin: 0
    }
    .contentCol h2 {
        font-size: 16px;
        line-height: 22px;
    }
}

