.element {
    width: 1100px!important;
    border-radius: 16px 0 0 16px;
    padding: 20px;
    top: 75px!important
}

.title {
    font-size: 32px;
    color: #3B5222;
    font-weight: 600;
    margin-bottom: 16px;
    margin-left: 12px
}

.header {
    padding-top: 16px;
    padding-bottom: 0;
    flex-direction: column;
    align-items: baseline;
}

.body {
    padding-top: 0;
}

.bodyContent {
    margin-bottom: 65px
}

.subjectRow {
    margin: 18px 0 0 0!important
}

.subjectRow h2 {
    font-size: 16px;
    color: #3B5222;
    font-weight: 600;
}

.subjectRow h3 {
    font-size: 14px;
    color: #3B5222;
    font-weight: 600;
}

.controlRow {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    padding: 15px 60px 30px 0;;
    background-color: #fff;
    z-index: 4
}

.transparent {
    position: absolute;
    bottom: 85px;
    width: 100%;
    height: 30px;
    background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(180deg, rgba(180, 0, 0, 0) 0%, rgba(255,255,255,1) 100%);
}

.ownDiplom {
    margin: 0!important
}

.ownDiplom h2 {
    font-size: 16px;
    color:#3B5222;
    font-weight: 600;
    margin: 18px 0;
}

.close {
    position: absolute;
    top: 40px;
    right: 80px;
}

.buttonRow {
    display: flex;
    align-items: center;
    justify-content: center;
}

.backButton {
    display: flex;
    flex-direction: row;
    align-items: center;
}

.disabled:hover {
    cursor: not-allowed;
    background-color: #fff;
}

@media only screen and (max-width: 1100px) {
    .element {
        width: 100%!important;
        border-radius: 0;
        padding: 20px;
        top: 65px!important;
        border-style: hidden!important;
        z-index: 1041;
    }
}

@media only screen and (max-width: 768px) {
    .close {
        top: 13px;
        left: 24px;
        right: 0;
        width: fit-content;
    }
    .element {
        width: auto!important;
        border-radius: 0;
        height: 100%!important;
        border-style: hidden!important;
        top: 0!important;
        z-index: 1043;
        padding: 20px 16px
    }

    .header {
        justify-content: center;
        align-items: initial;
        padding: 0;
        padding-bottom: 4px;
        margin-right: -24px;
        border-bottom: solid 1px #EAEAEA;
        width: calc(100% + 48px);
        margin-left: -24px
    }
    
    .title {
        font-size: 18px;
        text-align: center;
    }
    .body {
        padding: 16px 0!important
    }

    .subjectRow {
        margin-left: 0!important;
        margin-right: 0!important;
    }

    .controlRow {
        padding: 15px 20px 30px 5px;
    }
}
