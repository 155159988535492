.bodyContent {
    margin-bottom: 65px
}

.body {
    padding-top: 0;
}

.transparent {
    position: absolute;
    bottom: 85px;
    width: 100%;
    height: 30px;
    background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(180deg, rgba(180, 0, 0, 0) 0%, rgba(255,255,255,1) 100%);
}

.element {
    width: auto!important;
    border-radius: 0;
    height: 100%!important;
    border-style: hidden!important;
    padding: 20px;
    z-index: 99999
}

.close {
    position: absolute;
    top: 13px;
    left: 24px;
}

.header {
    justify-content: center;
    align-items: initial;
    padding: 0;
    padding-bottom: 4px;
    margin-right: -24px;
    border-bottom: solid 1px #EAEAEA;
    width: calc(100% + 48px);
    margin-left: -24px
}
.title {
    font-size: 18px;
    text-align: center;
    color: #3B5222;
    font-weight: 600;
    margin-bottom: 8px
}

.controlRow {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    padding: 15px 45px 30px 0;;
    background-color: #fff;
    z-index: 4
}