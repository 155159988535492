.wrapper {
    box-shadow: 0 4px 40px 10px rgb(9, 12, 6, 0.03);
    padding: 30px;
    border-radius: 8px;
    border: solid 1px #fafafa;
    margin-top: 30px;
}

.wrapper h2 {
    color: #3B5222;
    font-size: 18px;
    font-weight: 600;
}

.wrapper p {
    color: #3B5222;
    font-size: 16px;
}

.contactRow {
    margin: 0!important;
}

@media only screen and (max-width: 768px) {
    .wrapper {
        margin-top: 32px;
        background-color: #F6F6F6;
        text-align: center;
        margin-bottom: 40px;
    }
}