.wrapper {
    text-align:center;
    padding: 60px 0 0 0;
}

.preHeader, .header, .guiltyHeader {
    margin: 0!important
}

.preHeader p {
    font-size: 14px;
    font-weight: 600;
}

.header h1 {
    color: #3B5222;
    font-weight: 600;
    font-size: 100px;
}

.guiltyHeader h2 {
    color: #3B5222;
    font-weight: 600;
    font-size: 21px;
    padding-top: 50px;
    padding-bottom: 8px
}

.imgRow {
    justify-content: center;
    padding: 20px 0 60px 0;
    display: flex;
    flex-direction: row;
    width: 100%
}

@media only screen and (max-width: 768px) {
    .wrapper {
        padding: 30px 24px
    }

    .imgRow {
        flex-direction: column;
        padding-bottom: 0;
    }
}