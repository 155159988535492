.cardContent {
    width: 100%;
    padding: 0 0 0 24px!important
}
.iconRow {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin: 12px 0
}
.iconRow p {
    font-weight: 600;
}

.headerIcon {
    font-size: 18px;
    margin-right: 8px;
}


.diplomNotSet {
    color: #B4B4B4;
    font-weight: 600;
}

.diplomNotSet p {
    color: #B4B4B4
}

.gotIn {
    color: #639C26;
    font-weight: 600;
}

.gotIn p {
    color: #639C26
}

.notIn {
    color: #D02B2B;
    font-weight: 600;
}

.notIn p {
    color: #D02B2B
}

.previousYearsInfo h3 {
    font-size: 18px;
    color: #3B5222;
    font-weight: 600;
    padding: 0;
    margin: 8px 0 16px 0
}

.tableRow {
    width: 100%;
    border-radius: 5px;
    background-color: #F6F6F6;
    display: flex;
    padding: 20px;
    margin-bottom: 8px;
}

.yearColumn {
    margin-right: 20px;
    font-size: 16px;
    font-weight: 600;
    color:#3B5222
}

.tableTextNormal {
    font-size: 16px;
    font-weight: 600;
    color:#3B5222
}

.headerRow {
    width: 100%;
    color: #949494;
    font-size: 14px;
    padding: 8px 20px
}

.headerColumn {
    margin-right: 20px;
}

.noRequirementsRow {
    display: flex;
    flex-direction: row;
    padding: 0;
    margin-bottom: 24px;
}
.noRequirementsRow p {
    font-weight: 600;
}
