.element {
    border: solid 1px #3B5222;
    border-radius: 5px;
    padding: 10px 20px;
    width: fit-content;
    background-color: #fff;
    position: relative;
    z-index: 0
}

.dropdown {
    position:absolute;
    margin-top: 70px;
    border: solid 1px #EAEAEA;
    border-radius: 5px;
    padding: 0 24px;
    background-color: #fff;
    box-shadow: 0 4px 40px 10px rgb(9, 12, 6, 0.03);
    max-height: 500px;
    overflow-y: scroll;
    z-index: 2
}

.element h5, .dropdown h5 {
    color: #3B5222;
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 2px;
}

.element p {
    color:#949494;
    font-size: 12px;
    margin-bottom: 0;
}

.element:hover {
    cursor: pointer
}

.dropdownRow {
    display:flex;
    flex-direction: row;
}

.dropdownChevronColumn {
    margin-left: 16px;
}

.dropdownContainer {
    display: flex;
    width:fit-content;
    flex-direction: column;
}

.flexRow {
    align-items: center;
    display: flex;
    flex-direction: row;
    position:relative;
    z-index:8
}

.grow {
    display: flex;
    flex-grow: 1;
}

.shrink {
    display: flex;
    flex-grow: 0;
}

.search {
    background-color: #F6F6F6;
    border-style: hidden;
    border-radius: 5px;
    padding: 12px 16px 12px 50px;
    color:#565656;
    width: 100%
}

.searchContainer {
    background-color: #fff;
    padding: 8px 0;

}

.searchColor {
    color:#949494;
    position: absolute;
    margin-left: 18px;
    margin-top: 10px;
    font-size: 20px;
    width:fit-content
}

.contentWrapper {
    margin: -12px 0 0 0;
}

.contentElement {
    margin: 10px 0
}

.contentElement:last-child {
    margin-bottom: -4px
}

.fixedRow {
    position: sticky;
    bottom: 0;
    background-color: #fff;
    width: 100%;
    padding: 16px 0
}

.saveButton, .saveButton:focus, .saveButton:active, .saveButton:hover {
    width: 100%!important;
}

.saveButton:focus {
    width: 100%!important;
}


.transparent {
    position: sticky;
    bottom: 70px;
    width: 100%;
    height: 20px;
    background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(180deg, rgba(180, 0, 0, 0) 0%, rgba(255,255,255,1) 100%);
}

.fixedTop {
    position: sticky;
    display:flex;
    flex-direction: column;
    top: 0;
    background-color: #fff;
    padding: 8px 0 0;
    z-index: 5;
}

.transparentReverse {
    position: sticky;
    top: 105px;
    width: 100%;
    height: 15px;
    background: -moz-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(0deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(0deg, rgba(180, 0, 0, 0) 0%, rgba(255,255,255,1) 100%);
}

.closeButton {
    color:#949494;
    position: absolute;
    right: 20px;
    margin-top: 12px;
    font-size: 16px;
    width:fit-content;
    transition: all ease-in 0.1s;
}

.closeButton:hover {
    color:#757575;
    cursor: pointer
}

.canvas {
    position: absolute;
    top: 0;
    left: 0;
    width: calc(100% + 22vw);
    height: 325%;
    z-index: 1;
    margin-top: -480px;
    margin-left: -11vw;
    margin-right: -11vw;
}

.noResults {
    display: flex;
    flex-direction: row;
    margin-top: 4px;
    padding: 0 8px;
    max-width:fit-content;
    font-weight: 500;
}

@media only screen and (max-width: 768px) {
    .element {
        border: solid 1px #D6D6D6;
        border-radius: 5px;
        padding: 10px 20px;
        width: 100%;
        margin-bottom: 8px;
    }

    .dropdownContainer {
        width: 100%;
    }
    .dropdownChevronColumn p {
        color: #639C26;
        font-size: 16px;
        font-weight: 500;
    }
    .dropdownRow {
        align-items: center;
        justify-content: space-between;
    }
}