.sortWrapper {
    background-color: #FCFBFB;
    padding: 30px 0 20px 0
}

.chooser {
    border: solid 1px #3B5222;
    border-radius: 5px;
    padding: 8px 16px;
    width: fit-content;
    display: flex;
    flex-direction: row;
    align-items: center;
    background-color: #fff;
}

.chooser p {
    color: #3B5222;
    font-size: 16px;
    font-weight: 600;
}

.chooser:hover {
    cursor:pointer
}

.element {
    display: flex;
    flex-direction: column;
}

.dropdown {
    position:absolute;
    border: solid 1px #EAEAEA;
    border-radius: 5px;
    padding: 8px;
    background-color: #fff;
    box-shadow: 0 4px 40px 10px rgb(9, 12, 6, 0.03);
    margin-top: 50px;
    margin-left: -161px;
    width:314px
}

.dropdownRow {
    display: flex;
    flex-direction: row;
    border-radius: 5px;
    padding: 12px;
    color: #3B5222;
    transition: all ease-in 0.2s
}
.dropdownRow:first-child {
    margin-bottom: 4px
}
.dropdownRow:hover {
    cursor:pointer;
    background-color: #F6F6F6;
}

.disabled {
    color: #949494;
}

.disabled:hover {
    cursor:auto;
    background-color: #fff;
}

.icon {
    margin-right: 8px
}

.chosen {
    background-color: #F2FFE4;
    color: #639C26;
    font-weight: 500;
}

.chosen:hover {
    background-color:#e3f7ce;
}

.checkIcon {
    margin-left: 30px;
}

.checkIconShorter {
    margin-left: 85px;
}

.chevronIcon {
    margin-left: 20px;
}

@media only screen and (max-width: 768px) {
    .icon {
        margin-right: 0
    }
}