.element {
    background-color: #F6F6F6;
    border-radius: 5px;
    padding: 16px 24px;
    display: flex;
    flex-direction: row;
    margin: 0 8px 12px 8px!important;
    align-items: center;
}

.subject {
    display: flex;
    flex-direction: row;
    width: 35%;
    max-width: 35%
}

.subject h2 {
    font-size: 16px;
    font-weight: 600;
    color: #3B5222;
}

.grade {
    display: flex;
    flex-direction: row;
    width: 60%;
    max-width: 60%
}

.delete {
    display: flex;
    flex-direction: row;
    width: 5%;
    max-width: 5%;
    justify-content: end;
}

.deleteIcon {
    color: #BFBFBF;
    transition: all ease-in 0.2s;
}

.deleteIcon:hover {
    color: #D02B2B;
    cursor: pointer;
}

.gradeItem {
    font-size: 16px;
    border-radius: 20px;
    padding: 4px 30px;
    border: solid 1px #DDDDDD;
    color:#3B5222;
    background-color: #fff;
    margin-right: 8px;
    display: flex;
    width: fit-content;
    animation: fade-out 0.2s;
    transition: all ease-in 0.2s;
}

.activeItem {
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px;
    padding: 4px 30px;
    border: solid 1px #639C26;
    color:#fff;
    margin-right: 8px;
    background-color: #639C26;
    display: flex;
    width: fit-content;
    animation: fade-in 0.2s;
}

.activeItem:hover, .activeItemLast:hover {
    cursor: pointer;
}

.gradeItem:hover, .gradeItemLast:hover {
    border: solid 1px #639C26;
    cursor: pointer;
}

@media only screen and (max-width: 1200px) {
    .element {
        flex-wrap: wrap;
        align-items: baseline;
        padding: 20px;
        margin: 0 8px 12px 8px!important
    }
    .grade {
        order: 3;
        width: 100%;
        max-width: 100%;
        flex-direction: column;
    }
    .gradeItem, .activeItem {
        width: 33.33333%;
        max-width: 33.33333%;
        text-align: center;
        justify-content: center;
    }
    .subject {
        width: 80%;
        max-width: 80%;
        white-space: normal;
    }
    .delete {
        width: 20%;
        max-width: 20%;
        justify-content: end;
    }
    .mobileRow {
        display: flex;
        flex-direction: row;
        margin-top: 8px;
    }

    .subject h2 {
        font-size: 16px;
        line-height: 24px;
    }
}

@keyframes fade-in {
    from {background-color: #fff; border-color: #DDDDDD}
    to {background-color: #639C26; border-color: #639C26}
}

@keyframes fade-out {
    from {background-color: #639C26; border-color: #639C26}
    to {background-color: #fff; border-color: #DDDDDD}
}