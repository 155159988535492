.bodyContent {
    margin-bottom: 95px
}

.body {
    padding-top: 0;
}

.transparent {
    position: absolute;
    bottom: 85px;
    width: 100%;
    height: 30px;
    background: -moz-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: -webkit-linear-gradient(180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,1) 100%);
    background: linear-gradient(180deg, rgba(180, 0, 0, 0) 0%, rgba(255,255,255,1) 100%);
}

.element {
    width: auto!important;
    border-radius: 10px 10px 0 0;
    height: 80%!important;
    border-style: hidden!important;
    padding: 20px;
}

.close {
    position: absolute;
    top: 13px;
    left: 24px;
}

.reset {
    position: absolute;
    top: 13px;
    right: 24px;
}

.controlRow {
    position: absolute;
    bottom: 0;
    display: flex;
    width: 100%;
    padding: 15px 45px 30px 0;;
    background-color: #fff;
    z-index: 4
}


.header {
    justify-content: center;
    align-items: initial;
    padding: 0;
    padding-bottom: 16px;
    margin-right: -24px;
    border-bottom: solid 1px #EAEAEA;
    width: calc(100% + 48px);
    margin-left: -24px
}
.title {
    font-size: 18px;
    text-align: center; 
    color: #3B5222;
    font-weight: 600;
}

.search {
    background-color: #F6F6F6;
    border-style: hidden;
    border-radius: 5px;
    padding: 12px 16px 12px 50px;
    color:#565656;
    width: 100%
}

.searchColor {
    color:#949494;
    position: absolute;
    margin-left: 18px;
    margin-top: 10px;
    font-size: 20px;
    width:fit-content
}

.searchContainer {
    background-color: #fff;
    padding: 16px 0;
    position: relative;
}

.contentElement {
    margin: 10px 0
}

.contentElement:last-child {
    margin-bottom: -4px
}

.closeButton {
    color:#949494;
    position: absolute;
    right: 20px;
    margin-top: 12px;
    font-size: 16px;
    width:fit-content;
    transition: all ease-in 0.1s;
}

@media only screen and (max-width: 768px) {
    .body {
        padding: 0!important
    }
    .controlRow {
        position: absolute;
        bottom: 0;
        display: flex;
        width: 100%;
        padding: 15px 30px 30px 15px;
        background-color: #fff;
        z-index: 4
    }
    .search {
        width: calc(100% - 4px);
        margin: 0 2px
    }
}