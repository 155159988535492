.headerWrapper {
    background-color: #F9FEF4;
    padding: 80px 0 60px 0
}

.headerWrapper h1 {
    font-size: 36px;
    color: #3B5222;
    font-weight:600;
}

.headerWrapper p {
    font-size: 16px;
}

.headerContainer {
    max-width: 1000px!important;
}

.alignItems {
    align-items: center;
}

.infoAlign {
    text-align: end;
}

.ad {
    margin-top: 60px;
    max-width: fit-content!important;
    display: flex;
}

.image {
    width: inherit;
    border-radius: 10px;
}

.fullWidth {
    width: 100%;
}

@media only screen and (max-width: 768px) {
    .headerWrapper h1 {
        font-size: 28px;
    }

    .headerWrapper {
        padding: 40px 0
    }
    
    .infoAlign {
        text-align: center;
    }

    .ad {
        max-width: 100%!important;
        margin-top: 40px;
    }

    .image {
        width: 100%
    }
}

