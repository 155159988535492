.footerWrapper {
    background-color: #2C3F18;
    padding: 60px 0 30px 0
}

.footerWrapper h2 {
    color: #F2FFE4;
    font-size: 21px;
}

.footerWrapper p {
    color: #AAC190;
    font-size: 14px;
}

.column {
    display: flex;
    flex-direction: column;
    padding-left: 100px;
}

p.name {
    font-size: 16px;
}

.footerLink {
    color: #AAC190;
    text-decoration: none;
    margin-bottom: 12px;
    transition: all ease-in 0.2s;
}

.footerLink:hover {
    color: #fff;
    text-decoration: underline;
}

.img {
    width: 120px
}

.motto {
    width: 100%;
    padding: 0 30px;
    margin-top: 16px
}

.icon {
    color: #F2FFE4;
}

.iconWrapper {
    max-width: 80px
}

.copyright p {
    color: #AAC190;
    font-size: 16px;
    margin-top: 30px
}

@media only screen and (max-width: 1100px) {
    .column {
        padding-left:28px;
    }

    .column:nth-child(1) {
        padding-left: 100px;
    }
}

@media only screen and (max-width: 950px) {
    .column {
        padding-left:28px;
    }

    .column:nth-child(1) {
        padding-left: 60px;
    }
}

@media only screen and (max-width: 768px) {
    .footerWrapper {
        padding: 40px 20px
    }
    .column {
        padding-left: 0;
        margin-top: 36px;
        text-align: center;
    }

    .column:nth-child(1) {
        padding-left: 0
    }

    .copyright p {
        font-size: 14px;
        margin-bottom: 0;
    }
    
}