.headingRow h2 {
    color: #3B5222;
    font-weight: 600;
    font-size: 18px; 
}

.search {
    width: 150px;
    border: solid 1px #EAEAEA;
    border-radius: 5px;
    padding: 12px;
    color:#565656;
    margin-right: 8px;
    display: flex;
    flex-grow: 1;
}


.search::placeholder {
    color: #949494
}

.inputRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 8px 0 0 0;
    max-width: 100%;
}

.alertRow {
    display: flex;
    flex-direction: row;
    color: #D02B2B;
    align-items: center;
    margin-top: 12px
}

.alertRow p {
    font-weight: 600;
    font-size: 14px
}

.buttonWrapper {
    display: flex;
    flex-grow: 0;
    width:fit-content
}

@media only screen and (max-width: 768px) {
    .search {
        margin-right: 0;
    }
}