.navbar {
    border-bottom: solid 1px #f5f5f5;
    box-shadow: 0 0 20px 16px rgba(0,0,0,0.02);
    position: fixed;
    top: 0;
    background-color: #fff;
    z-index: 99999
}

.navContent {
    max-width: 600px
}

.navLink {
    text-decoration: none;
    color: #3B5222;
    margin-right: 16px;
    transition: all 0.2s;
    padding: 5px 16px;
    background-color: #fff;
    border-radius: 5px
}

.navLink:last-child {
    text-decoration: none;
    color: #3B5222;
    margin-right: 0!important
}

.navLink:hover {
    color:#3B5222;
    background-color: #F6F6F6;
}

.active {
    color:#639C26;
    font-weight: 500;
    background-color:#F2FFE4;
}

.active:hover {
    color:#639C26;
    font-weight: 500;
    background-color:#e3f7ce;
}

@media only screen and (max-width: 1100px) {
    .navbar {
        display: none!important;
    }
}