.default {
    font-size: 16px;
    border-radius: 20px;
    padding: 4px 16px;
    border: solid 1px #DDDDDD;
    color:#3B5222;
    margin-left: 8px;
    display: flex;
    width: fit-content;
    animation: fade-out 0.2s;
    margin-bottom: 8px;
    transition: all ease-in 0.2s;
}

.default:hover {
    border: solid 1px #639C26;
    cursor: pointer;
}

.chosen {
    font-size: 16px;
    font-weight: 500;
    border-radius: 20px;
    padding: 4px 16px;
    border: solid 1px #639C26;
    color:#fff;
    margin-left: 8px;
    background-color: #639C26;
    display: flex;
    width: fit-content;
    animation: fade-in 0.2s;
    margin-bottom: 8px
}

@keyframes fade-in {
    from {background-color: #fff; border-color: #DDDDDD}
    to {background-color: #639C26; border-color: #639C26}
}

@keyframes fade-out {
    from {background-color: #639C26; border-color: #639C26}
    to {background-color: #fff; border-color: #DDDDDD}
}

.chosen:hover {
    cursor: pointer;
}